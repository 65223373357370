import Axios from 'axios';

// *base url to make request to database
// const baseURL="https://embassybar.herokuapp.com/api/"
// const baseURL="http://127.0.0.1:8000/api/"
const baseURL="https://embassy.com.hk/index.php/api/"

const instance=Axios.create({
            baseURL:baseURL
        });
export default instance