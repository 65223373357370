import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import MyComponent from '../components/Googlemap';
import styled from 'styled-components';
import api from '../components/axios'

import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import {Contactus} from "./Styledcs"

import ReCAPTCHA from "react-google-recaptcha";

const Divbox = styled.div`
flex:1;
height:420px ;
display: inline-block;
overflow: hidden;
`;


export default function ContactUs() {
  const endpoint="shop"
  const postendpoint="/contactus/"
  const [shopdetails, setShopdetails]=useState([])
  const [cname, setCname] = useState()
  const [cemail, setCemail] = useState()
  const [cmessage, setCmessage] = useState()
  const [captchaResult, setCaptchaResult] = useState()

  const [cnameerror, setCnameerror] = useState()
  const [cemailerror, setCemailerror] = useState()
  const [cmessageerror, setCmessageerror] = useState()
  const [cmessagesent, setCmessagesent] = useState()


  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleRecaptcha = (value) => {

    fetch('https://embassy.com.hk/index.php/api/recaptcha/', {
      method: 'POST',
      body: JSON.stringify({ 'captcha_value': value }),
      headers: { 'Content-Type': 'application/json' }
    })
     .then(res => res.json())
     .then(data => {
       console.log(data.captcha.success)
       setCaptchaResult(data.captcha.success)
     }) 
  }

console.log(captchaResult)
 function error(){
  if(cnameerror||cemailerror||cmessageerror){

  }

 }

  function sendPost(){
    if (cname, cemail, cmessage){

      api.post(postendpoint,{
        contactus_name:cname,
        contactus_email:cemail,
        contactus_message:cmessage,
      },
      setCemail(''),
        setCname(''),
        setCmessage(''),
        setCmessagesent("Message Sent !")
      ).then(res=>{
        
        console.log(res.data,+'emanil:'+cemail);
      })
    }
    else if (!cname){
      setCnameerror("Name Missing")
    }

    else if (!cemail){
      setCemailerror("Email Missing")
    }
    else if (!cmessage){
      setCmessageerror("Message Missing")
    }
  
  }

  useEffect(()=>{
    api.get(endpoint).then(res=>{
      setShopdetails(res.data)
      
    })
  },[cname])

  const bgimage=shopdetails.map(d=>d.shop_image)[0]
  console.log(bgimage)
  return (
    <Contactus style={{
      backgroundImage:`url("https://embassy.com.hk/images/shop/Embassy Club.jpg")`,backgroundPosition: "center", 

    }}>
 <Divbox  className='googlemap'>
  <MyComponent/>

 
  </Divbox>
       

<Divbox id="contactus"  className='contact us'   
item md={4} data-aos='fade-right'
  data-aos-duration='1000'
  data-aos-once='true'
  data-aos-anchor-placement='center bottom'
  style={{ 
    display:"flex", color:"#ebe0bd",  flexDirection:"column",
    alignItems:"center",justifyContent:"center",}}
    > 
 
 
 
 {cmessagesent?<div style={{width:'80%', height:"80%", backgroundColor:"#000",opacity:'0.8', display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}><h2>Message Sent!</h2>
 <p>Thank you for your message <br/>we will keep you posted...</p></div>:

<FormControl style={{alignItems:"center",justifyContent:"center",margin:"3em",flexDirection:"column", backgroundColor:"rgba(0, 0, 0, 0.8)", width:"70%" }} >
<Typography variant="h4" style={{color:"#ebe0bd", margin:"20px",  fontSize:"130%"}} align="center" component="h3">
    Message us
</Typography> 
 

      <TextField type="text" 
      value={cname}
      onChange={(e)=>{setCname(e.target.value)}} 
      id="outlined-basic" 
      label="Name" variant="filled" 
      style={{ width:'80%', color:"#000", backgroundColor: "#ebe0bd", margin:'4px'}} />
     
       <span>{cnameerror}</span>
      <TextField 
      value={cemail}
      onChange={(e)=>{
        if(!isValidEmail(e.target.value)) {
          setCemailerror('Email is invalid');
        } else {
          setCemailerror(null);
          setCemail(e.target.value);
        }}
         } 
      id="outlined-basic" label="Email" variant="filled"  
      style={{color:"#000", backgroundColor: "#ebe0bd",width:'80%', margin:'0px 4px 2px 4px'}} type="email" />
       <span>{cemailerror}</span>
      <TextField type="text"
      value={cmessage}
          id="filled-multiline-static"
          label="Message"
          multiline
          rows={2}
          variant="filled"
          style={{width:'80%',color:"#000", backgroundColor: "#ebe0bd", margin:'2px 4px'}}
          onChange={(e)=>{setCmessage(e.target.value)}}
        /> 
 
 <div className="cta" >
          <ReCAPTCHA
          style={{transform:"scale(0.80)", transformOrigin:"0 0"}} 
            sitekey="6Lc41mQiAAAAADTCKimKYD22FLdZaa_VJrT1t59m"
            onChange={handleRecaptcha}
            theme='dark'
            data-size="compact"
          />
          
          
        </div>

 {!cname || !cemail ||!captchaResult || cemailerror ||!cmessage?
(<div style={{width:'80%', opacity:"0.5"}}><Button variant="contained"
disabled

endIcon={<SendIcon />}>
  Send Message
</Button></div>):
(<div style={{width:'80%', opacity:"1"}}>
   <Button variant="contained"
 endIcon={<SendIcon />} 
 onClick={sendPost}>
  Send Message
</Button></div>)}

 

<br/>
      </FormControl>}
      
      
    
</Divbox>      
 
     

  
        
{shopdetails.map((data, key) => (

<Divbox  className='openinghour'  
container key={key}   
style={{
  display:"flex", color:"#ebe0bd",  
  backgroundImage:`url(${bgimage})`,
  alignItems:"center",justifyContent:"center"
}}
data-aos='fade-right'
  data-aos-duration='1000'
  data-aos-once='true'
  data-aos-anchor-placement='center bottom'> 
<Container style={{ margin:"3em", backgroundColor:"rgba(0, 0, 0, 0.8)", display:"inline-block", width:"70%"}}>
<Typography variant="h4" marked="left" style={{color:"#ebe0bd", marginTop:"20px" ,fontSize:"130%"}} align="center" component="h3">

OPEN

</Typography> 
   

 
<p  align="center" style={{lineHeight:"2.5",}}><CalendarTodayIcon style={{paddingTop:"8px"}}/> {data.shop_opendays}</p>  
<p  align="center" style={{lineHeight:"2.5",}}><AccessTimeIcon style={{paddingTop:"8px"}}/> {data.shop_open_close_time} </p>  



   
<a style={{color:"#ebe0bd", lineHeight:"2.5",}} href={`tel:${data.shop_phone}`} rel="noopener noreferrer" target='_blank'><p  align="center" ><CallIcon style={{paddingTop:"8px"}}/> {data.shop_phone}</p></a>
<a style={{color:"#ebe0bd", lineHeight:"2.5",}} href={`mailto:${data.shop_email}`} rel="noopener noreferrer" target='_blank'><p  align="center" ><EmailIcon style={{paddingTop:"8px"}}/> {data.shop_email}</p></a>

<h4 style={{marginBottom:'0px' ,lineHeight:"2.5",}}  align="center" >< LocationOnIcon style={{paddingTop:"8px"}}/> Address</h4>
<div style={{paddingLeft:'30px',lineHeight:"2.5", }}  align="center" >
{data.shop_address} <br/>
 
<br/></div>

  
   
</Container>
</Divbox>      
))}


 </Contactus>
  );
}
