import React, {useState, useEffect} from 'react';
import {Section, Content,Leftsection,LeftsectionDestails, Rightsection, LargeImage, Item, Wrapper} from './Styledcs'
import Typography from '../components/Typography';
import api from '../components/axios'

const Aboutus = () => {
  const endpoint="shop"

  const [aboutus, setAboutus]=useState([])

  useEffect(()=>{
    api.get(endpoint).then(res=>{
      setAboutus(res.data) 

    })
  },[])
  return (
    <div id="aboutus">
    
    <Section>
      <Content>
      {aboutus.map((item,index)=> 

    (
        <Wrapper key={index}>
          <Leftsection>
          
            <div
              data-aos='fade-right'
              data-aos-duration='1200'
              data-aos-delay='300'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            >
              <Typography style={{color:'#000',fontSize:'130%'}} variant="h1" marked="center" align="left" component="h2" data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>
      Welcome to {item.shop_name}
      </Typography>
              
              
                
               <br/>

                
                 
                    
                 <LeftsectionDestails dangerouslySetInnerHTML={{__html: item.shop_description}}  ></LeftsectionDestails> 
            </div>
          </Leftsection>
          <Rightsection>
            <LargeImage
              src={item.shop_image}
              data-aos='fade-left'
              data-aos-duration='1200'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            />
          </Rightsection>
        </Wrapper>  
      ))}
     </Content>
     </Section>
   </div>
  );
};

export default Aboutus;
